<template>
    <div class="content-wrapper">
        <PageHeader screenName="Ajuda e suporte" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="registro"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="changeStateAjuda"
            @canceled="cancelDelete"
        />

        <TimedAlert variant="danger" :message="alert.errorMessage" :show="alert.show" />

        <div class="card hidden_overflow_tabela">
            <div class="card-header p-2 border-bottom">
                <b-button variant="primary-button" @click="redirectToRegister()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Adicionar novo tópico de ajuda</span>
                </b-button>
            </div>
            <div class="pt-2">
                <TableFilter :filter="filter" @filterTable="loadDataAjuda" />
            </div>
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="card-body py-2 px-0" v-if="!loadingData">
                <section class="list-ajuda">
                    <b-table
                        class="m-0"
                        id="tableAjuda"
                        responsive
                        :stickyColumn="true"
                        :busy.sync="filtering"
                        :fields="table.fields"
                        :items="table.items"
                        @sort-changed="ordenarColuna"
                        no-local-sorting
                        :no-sort-reset="true"
                    >
                        <template #cell(faq)="row">
                            <span>{{ row.item.topico }}</span>
                        </template>
                        <template #cell(categoria)="row">
                  <span class="rounded categorias">
                    {{ row.item.categoria }}
                  </span>
                        </template>
                        <template #cell(ativo)="row">
                            <TableSwitch
                                :checked="row.item.ativo"
                                :description="row.item.id_ajuda+row.item.topico"
                                :itemId="row.item.id_ajuda"
                                @stateModified="openModalConfirm"
                            />
                        </template>
                        <template #cell(actions)="row">
                            <feather-icon
                                @click.prevent="redirectToView(row.item)"
                                id="view-ajuda"
                                role="button"
                                class="text-custom-blue mr-1"
                                icon="EyeIcon"
                                size="18"
                            />
                            <feather-icon
                                @click.prevent="redirectToEdit(row.item)"
                                id="edit-ajuda"
                                role="button"
                                class="text-custom-blue"
                                icon="EditIcon"
                                size="18"
                            />
                        </template>
                    </b-table>
                    <span v-if="withoudAjuda" class="ml-1">Nenhuma ajuda encontrada</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                    </b-col>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';

export default {
    title: 'Ajuda e Suporte',
    components: {
        BForm, BCol, BRow, BFormGroup, BFormInput,
        BButton, BTable, TableSwitch,
        vSelect, CustomPagination, PageHeader,
        ConfirmModal, TimedAlert, TableFilter
    },

    data() {
        return {
            alert: {
                errorMessage: '',
                show: false
            },
            linkItems: [
                {
                    name: 'Ajuda e suporte',
                    active: true
                }
            ],
            timeID: null,
            filtering: false,
            filter: false,
            loadingData: true,
            withoudAjuda: false,
            searchTerm: '',
            table: {
                fields: [
                    {key: "topico", label: "Tópico de ajuda", sortable: true, class: 'mw-400'},
                    {key: "categoria", label: "Categoria", sortable: true, class: 'mw-100'},
                    {key: "atualizado", label: "Atualizado em", sortable: true, class: 'mw-100'},
                    {key: "ativo", label: "Status", sortable: true, class: 'mw-50'},
                    {key: "actions", label: "Ações", class: 'mw-150 text-center col-lg-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'topico',
                order: 'asc'

            },
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            deleteItem: null,
            modal: {
                showModal: false,
                typeModal: 'success',
            }
        }
    },

    mounted() {
        this.loadDataAjuda(null, true);
    },

    methods: {
        loadDataAjuda(filterData = null, first = false) {
            this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10;

            if(filterData && filterData.hasOwnProperty('currentPage')) { this.paginationData.currentPage = filterData.currentPage; }

            const parameters = {
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order
            };

            this.filtering = true;

            this.$http.get(this.$api.ajuda(), { params: parameters }).then(({ data }) => {
                this.table.items = data.data;

                if(data.data.length == 0) {
                    this.withoudAjuda = true;
                }

                if(first) {
                    this.loadingData = false;
                }
                this.filtering = false;
                this.startPagination(data)
            })
        },

        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordenarColuna(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;
            this.filter = !this.filter;
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.filter = !this.filter;
        },

        redirectToEdit({ id_ajuda,topico,conteudo,id_categoria }) {
            this.$router.push(
                {
                    name: 'ajuda-edit',
                    params: {
                        idAjuda: id_ajuda,
                        topico: topico,
                        conteudo: conteudo,
                        categoriaSelecionada: id_categoria,
                    }
                }
            );
        },

        redirectToView({ id_ajuda,topico,conteudo,categoria,id_categoria}) {
            this.$router.push(
                {
                    name: 'ajuda-view',
                    params: {
                        idAjuda: id_ajuda,
                        topico: topico,
                        conteudo: conteudo,
                        categoria: categoria,
                        id_categoria: id_categoria,
                    }
                }
            );
        },

        openModalConfirm(values) {
            this.modal.typeModal = 'warning';

            if(values.state) {
                this.modal.typeModal = 'success';
            }

            this.$helpers.changeStateActiveTableRow(this.table.items, values, 'id_ajuda');

            this.deleteItem = values;
            this.modal.showModal = true;
        },

        changeStateAjuda() {
            this.$http.patch(this.$api.ajudaIdAjuda(this.deleteItem.itemId)).then(() => {
                this.modal.showModal = false;
            }).catch(({ response }) => {
                this.modal.showModal = false;

                this.$helpers.backStateActiveTableRow(this.table.items, this.deleteItem, 'id_ajuda');

                this.alert.errorMessage = response.data.errors;
                this.alert.show = !this.alert.show;
            });

        },

        cancelDelete() {
            this.$helpers.backStateActiveTableRow(this.table.items, this.deleteItem, 'id_ajuda');

            this.modal.showModal = false;
        },

        redirectToRegister() {
            this.$router.push({name: 'ajuda-register'});
        },
    }
}
</script>

<style>
.hidden_overflow_tabela{
    overflow: hidden;
}
.categorias{
    background-color: #2772C0;
    color: #ffffff;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.altura_max_categorias_scrollbar{
    max-height:160px;
    padding-right: 15px;
}
.comprimento_maximo_celulas{
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}
.comprimento_maximo_tabela_header{
    width: 160px;
}
.comprimento_status{
    width: 100px;
}
.hidden_overflow_tabela{
    overflow: hidden;
}
</style>
